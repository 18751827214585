import React, { useCallback, useMemo, useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { ModalInMobile, Page } from '../../components';
import MainPanel from './MainPanel';
import { loadData } from './SearchPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import {
  createSearchResultSchema,
  pickSearchParamsOnly,
  validFilterParams,
} from './SearchPage.shared';
import SearchMap from './SearchMap/SearchMap';
import SignupPromptModal from './SignupPromptModal/SignupPromptModal';

import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 1024; // Search is in modal on mobile and tablet layout
const TABLET_STARTPOINT = 768;
const SEARCH_WITH_MAP_DEBOUNCE = 450; // Little bit of debounce before search is initiated.

const parseSearchOptions = { latlng: ['origin'], latlngBounds: ['bounds'] };

// bl = bottom left, tr = top right, p = point
const inBoundingBox = (bl, tr, p) => {
  // in case longitude 180 is inside the box
  let isLongInRange;

  if (tr.lng < bl.lng) {
    isLongInRange = p.lng >= bl.lng || p.lng <= tr.lng;
  } else {
    isLongInRange = p.lng >= bl.lng && p.lng <= tr.lng;
  }

  return p.lat >= bl.lat && p.lat <= tr.lat && isLongInRange;
};

export const SearchPageComponent = props => {
  const {
    listings: propListings,
    currentUser,
    pagination,
    scrollingDisabled,
    searchInProgress,
    searchListingsError,
    searchParams,
    suggestedListings,
    onLoadMoreListings,
    onManageDisableScrolling,
    history,
    location,
    intl,
    params,
    interfaceLang,
  } = props;

  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [activeListingId, setActiveListingId] = useState(null);

  const routes = useRouteConfiguration();
  const config = useConfiguration();

  const { listingFields: listingFieldsConfig } = config?.listing || {};
  const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

  const topbarClasses = classNames(css.topbar, {
    [css.topbarBehindModal]: isMobileModalOpen || isSearchMapOpenOnMobile,
  });

  const parsedSearch = useMemo(() => parse(location.search, parseSearchOptions), [location.search]);
  const { mapSearch, page, ...searchInURL } = parsedSearch || {};
  const { bounds, origin } = searchInURL || {};

  const listings = useMemo(
    () =>
      bounds
        ? propListings.filter(listing => {
            const { attributes } = listing;
            const { geolocation } = attributes;

            const { ne, sw } = bounds;

            if (!geolocation) return false;

            return inBoundingBox(sw, ne, geolocation);
          })
        : propListings,
    [bounds, propListings]
  );

  const { title, description, schema } = useMemo(
    () =>
      createSearchResultSchema(
        listings,
        searchInURL || {},
        intl,
        routes,
        config,
        params?.lang || interfaceLang
      ),
    [config, interfaceLang, intl, listings, params?.lang, routes, searchInURL]
  );

  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = useMemo(
    () =>
      pickSearchParamsOnly(
        searchInURL,
        listingFieldsConfig,
        defaultFiltersConfig,
        sortConfig,
        config.maps.sortSearchByDistance
      ),
    [
      config.maps.sortSearchByDistance,
      defaultFiltersConfig,
      listingFieldsConfig,
      searchInURL,
      sortConfig,
    ]
  );
  // Page transition might initially use values from previous search
  const urlQueryString = useMemo(() => stringify(urlQueryParams), [urlQueryParams]);
  const paramsQueryString = useMemo(
    () =>
      stringify(
        pickSearchParamsOnly(
          searchParams,
          listingFieldsConfig,
          defaultFiltersConfig,
          sortConfig,
          config.maps.sortSearchByDistance
        )
      ),
    [
      config.maps.sortSearchByDistance,
      defaultFiltersConfig,
      listingFieldsConfig,
      searchParams,
      sortConfig,
    ]
  );
  const searchParamsAreInSync = useMemo(() => urlQueryString === paramsQueryString, [
    paramsQueryString,
    urlQueryString,
  ]);
  const validQueryParams = useMemo(
    () => validFilterParams(searchInURL, listingFieldsConfig, defaultFiltersConfig, false),
    [defaultFiltersConfig, listingFieldsConfig, searchInURL]
  );
  const isMobileLayout = useMemo(
    () => typeof window !== 'undefined' && window.innerWidth < MODAL_BREAKPOINT,
    []
  );
  const isTabletLayout = useMemo(
    () =>
      typeof window !== 'undefined' &&
      window.innerWidth < MODAL_BREAKPOINT &&
      window.innerWidth >= TABLET_STARTPOINT,
    []
  );
  const shouldShowSearchMap = useMemo(
    () => !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile),
    [isMobileLayout, isSearchMapOpenOnMobile]
  );

  const onMapMoveEnd = useCallback(
    (viewportBoundsChanged, data) => {
      if (viewportBoundsChanged) {
        const { address, bounds, mapSearch, ...rest } = parsedSearch;
        const { viewportBounds, viewportCenter } = data;

        const originMaybe = config.maps.sortSearchByDistance ? { origin: viewportCenter } : {};
        const searchParams = {
          address,
          bounds: viewportBounds,
          mapSearch: true,
          ...(!mapSearch ? { savedBounds: bounds } : {}),
          ...originMaybe,
          ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig, false),
        };

        history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
      }
    },
    [
      config.maps.sortSearchByDistance,
      defaultFiltersConfig,
      history,
      listingFieldsConfig,
      parsedSearch,
      routes,
    ]
  );

  const onMapListingClick = useCallback(mapListings => {
    const listings = Array.isArray(mapListings) ? mapListings : [mapListings];

    const firstListing = listings?.[0];
    const listingEl = document.getElementById(`listing-${firstListing.id.uuid}`);

    const listingElements = [];

    for (let index = 0; index < listings.length; index++) {
      const listing = listings[index];

      const listingEl = document.getElementById(`listing-${listing.id.uuid}`);

      listingEl?.classList.add(css.highlightListing);

      listingElements.push(listingEl);
    }

    listingEl?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    listingEl?.focus();

    setTimeout(() => {
      listingElements.forEach(el => {
        el.classList.remove(css.highlightListing);
      });
    }, 2000);
  }, []);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={description}
      title={title}
      schema={schema}
    >
      <TopbarContainer
        className={topbarClasses}
        currentPage="SearchPage"
        currentSearchParams={urlQueryParams}
      />
      <div className={css.container}>
        <MainPanel
          urlQueryParams={validQueryParams}
          listings={listings}
          pagination={pagination}
          currentUser={currentUser}
          search={location.search}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          searchParamsAreInSync={searchParamsAreInSync}
          suggestedListings={suggestedListings}
          onActivateListing={setActiveListingId}
          onOpenModal={() => setIsMobileModalOpen(true)}
          onCloseModal={() => setIsMobileModalOpen(false)}
          onMapIconClick={() => setIsSearchMapOpenOnMobile(true)}
          onLoadMoreListings={onLoadMoreListings}
          onManageDisableScrolling={onManageDisableScrolling}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          history={history}
        />
        <ModalInMobile
          className={css.mapPanel}
          id="SearchPage.map"
          isModalOpenOnMobile={isSearchMapOpenOnMobile}
          onClose={() => setIsSearchMapOpenOnMobile(false)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.mapWrapper}>
            {shouldShowSearchMap && (
              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                onMapListingClick={onMapListingClick}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                location={location}
                listings={[...listings, ...suggestedListings]}
                onMapMoveEnd={debounce(onMapMoveEnd, SEARCH_WITH_MAP_DEBOUNCE)}
                onCloseAsModal={() => onManageDisableScrolling('SearchPage.map', false)}
                messages={intl.messages}
                intl={intl}
                isMobile={isMobileLayout}
                isTablet={isTabletLayout}
                currentUser={currentUser}
              />
            )}
          </div>
        </ModalInMobile>
      </div>
      <SignupPromptModal
        id="SignupPromptModal.searchPage"
        currentUser={currentUser}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </Page>
  );
};

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  suggestedListings: [],
};

SearchPageComponent.propTypes = {
  listings: arrayOf(propTypes.listing).isRequired,
  pagination: propTypes.pagination,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    listings,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    suggestedListings,
  } = state.SearchPage;

  const { interfaceLang } = state.ui;

  return {
    currentUser,
    listings,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    suggestedListings,
    interfaceLang,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onLoadMoreListings: search => dispatch(loadData(null, search)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
