import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { string } from 'prop-types';
import { intlShape, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { Modal, PrimaryButton } from '../../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../util/routes';

import css from './SignupPromptModal.module.css';

const SIGNUP_PROMPT_DELAY = 15000;

const SignupPromptModal = props => {
  const { id, className, rootClassName, currentUser, onManageDisableScrolling } = props;

  const [isSignupPromptModalOpen, setIsSignupPromptModalOpen] = useState(false);

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const routeConfiguration = useRouteConfiguration();

  const messages = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'SignupPromptModal.title' }),
      description: intl.formatMessage({ id: 'SignupPromptModal.description' }),
      buttonText: intl.formatMessage({ id: 'SignupPromptModal.buttonText' }),
    }),
    [intl]
  );

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const handleCloseModal = useCallback(() => {
    setIsSignupPromptModalOpen(false);
  }, []);

  const onCloseModal = useCallback(() => {
    const state = { from: `${location.pathname}${location.search}${location.hash}` };

    history.push(createResourceLocatorString('SignupPage', routeConfiguration, {}, {}), state);
  }, [history, location.hash, location.pathname, location.search, routeConfiguration]);

  useEffect(() => {
    const timeout = setTimeout(() => setIsSignupPromptModalOpen(!currentUser), SIGNUP_PROMPT_DELAY);

    return () => clearTimeout(timeout);
  }, [currentUser]);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      scrollLayerClassName={css.blur}
      isOpen={isSignupPromptModalOpen}
      onClose={handleCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <h2 className={css.modalTitle}>{messages.title}</h2>
      <p className={css.modalMessage}>{messages.description}</p>

      <div className={css.modalBottomWrapper}>
        <PrimaryButton className={css.modalButton} type="button" onClick={onCloseModal}>
          {messages.buttonText}
        </PrimaryButton>
      </div>
    </Modal>
  );
};

SignupPromptModal.defaultProps = {
  className: null,
  rootClassName: null,
};

SignupPromptModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default SignupPromptModal;
