import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { convertNumberToMoney, formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';
import { LISTING_TYPE } from '../../../constants/listingAttributes';

import { CUSTOMER_FEE_PERCENTAGE } from '../../../transactions/transactionsUtil';
import { getNormalizedFee } from '../../../util/sessions';

import css from './SearchMapPriceLabel.module.css';

const SearchMapPriceLabel = props => {
  const {
    className,
    rootClassName,
    listing,
    isActive,
    onListingClicked,
    intl,
    currentUser,
  } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const priceLabelClasses = useMemo(
    () => classNames(css.priceLabel, { [css.priceLabelActive]: isActive }),
    [isActive]
  );
  const caretClasses = useMemo(() => classNames(css.caret, { [css.caretActive]: isActive }), [
    isActive,
  ]);

  const currentListing = useMemo(() => ensureListing(listing), [listing]);

  // Create formatted price if currency is known or alternatively show just the unknown currency.
  const { price: listingPrice } = currentListing.attributes;

  const isCurrentUserAuthor = currentUser?.id?.uuid === currentListing?.author.id?.uuid;

  const buyerFee = isCurrentUserAuthor
    ? CUSTOMER_FEE_PERCENTAGE
    : currentUser?.attributes?.profile?.metadata?.customerFee;

  const feeAmount =
    listingPrice?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

  const price = useMemo(() => {
    return convertNumberToMoney(listingPrice?.amount + feeAmount, listingPrice?.currency);
  }, [feeAmount, listingPrice]);

  const formattedPrice = useMemo(
    () => (price && price.currency ? formatMoney(intl, price) : price.currency),
    [intl, price]
  );

  const isFree = useMemo(() => listing?.attributes?.publicData?.type === LISTING_TYPE.FREE, [
    listing,
  ]);

  return (
    <button className={classes} onClick={() => onListingClicked(currentListing)}>
      <div className={css.caretShadow} />
      <div className={priceLabelClasses}>
        {isFree ? <FormattedMessage id="General.free" /> : formattedPrice}
      </div>
      <div className={caretClasses} />
    </button>
  );
};

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
